.checkbox[data-v-8a87b16c] {
  margin-bottom: 0px !important;
}
.toolbar[data-v-8a87b16c] {
  display: flex;
  flex-direction: var(--8a87b16c-flexDirection);
}
.toolbar[data-v-8a87b16c] .input {
  margin-right: 8px;
  margin-bottom: var(--8a87b16c-margin);
}
.toolbar[data-v-8a87b16c] .date-input {
  width: 265px!important;
  flex-grow: 0;
}
.toolbar .sort-btn[data-v-8a87b16c] {
  margin-right: 0px;
}
